import React, { Suspense, useEffect, useRef, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Lottie from 'lottie-react';
import { Link, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { GOOGLE_CLIENT_ID } from './configs';
import './configs/firebase';
import Loading from './pages/Loading';
import { AdministrativeRouter, UserRouter } from './routes';
import LoginRoutes from './routes/login';
import { socket } from './socket';
import useBoundStore from './store';
import { isMobileDevice } from './utils';

import 'react-toastify/dist/ReactToastify.css';

import { SocketEvent, UserRole } from './types';

const App = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams({ token: '' });

  const { token: queryToken } = Object.fromEntries(searchParams);
  const token = localStorage.getItem('token');

  const getUserProfile = useBoundStore.use.getUserProfile();
  const userProfile = useBoundStore((state) => state.user);

  const getAllStations = useBoundStore.use.getAllStations();
  const toggleNotification = useBoundStore.use.newNotification();

  const headerSideRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const hasNewNotifications = useBoundStore.use.hasNewNotifications();
  const isAsideOpen = useBoundStore.use.isAsideOpen();
  const toggleAside = useBoundStore.use.toggleAside();
  const isAuthenticated = useBoundStore.use.isAuthenticated();
  const logout = useBoundStore.use.logout();

  useEffect(() => {
    const body = document.body;

    if (isAuthenticated) {
      body.classList.add('header-and-bottom-nav');
    } else {
      body.classList.remove('header-and-bottom-nav');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (queryToken && queryToken !== '') {
      navigate('/');
    }
  }, [queryToken, navigate]);

  // Get User Profile in first visiting
  useEffect(() => {
    if (token) {
      setLoading(true);
      getAllStations();
      getUserProfile().finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
    } else {
      logout();
    }
  }, [token, getUserProfile, getAllStations, logout]);

  useEffect(() => {
    const closeHeaderSide = (event: MouseEvent) => {
      if (headerSideRef.current && !headerSideRef.current.contains(event.target as Node)) {
        toggleAside();
      }
    };

    if (isAsideOpen) {
      setTimeout(() => {
        window.addEventListener('click', closeHeaderSide);
      }, 0);
      return () => {
        window.removeEventListener('click', closeHeaderSide);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsideOpen]);

  useEffect(() => {
    const onConnect = () => {
      console.log('Connected to server socket');
    };

    const onDisconnect = () => {
      console.log('Disconnected from server socket');
    };

    socket.on(SocketEvent.CONNECT, onConnect);
    socket.on(SocketEvent.DISCONNECT, onDisconnect);

    return () => {
      socket.off(SocketEvent.CONNECT, onConnect);
      socket.off(SocketEvent.DISCONNECT, onDisconnect);
    };
  }, []);

  useEffect(() => {
    const onReceiveNotification = () => {
      toggleNotification();

      console.log('New notification received');
      getUserProfile();
      toast.info(({ closeToast }) => (
        <Link to='/notifications' onClick={closeToast}>
          You have new messages
        </Link>
      ));
    };

    socket.on(SocketEvent.NOTIFICATION, onReceiveNotification);

    return () => {
      socket.off(SocketEvent.NOTIFICATION, onReceiveNotification);
    };
  }, [hasNewNotifications, toggleNotification, getUserProfile]);

  if (!isMobileDevice()) {
    return (
      <div className='flex h-full w-screen flex-col items-center justify-center'>
        <div className='flex h-full flex-col items-center justify-center text-center'>
          <Lottie
            animationData={require('./assets/animations/no-service.json')}
            className='h-[60%]'
          />
          <h1 className='mt-10 text-3xl font-bold'>Sorry, we do not support this device</h1>
          <p className='mt-4 text-lg'>
            Please visit this website on a mobile device to have the best experience
          </p>
        </div>
      </div>
    );
  }
  if (loading) return <Loading />;

  return (
    <>
      <Suspense fallback={null}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          {/* {isAuthenticated ? (
            userProfile.roles.includes(UserRole.ADMIN) ? (
              <Routes>
                <Route path='admin/*' element={<AdministrativeRouter />} />
                <Route path='*' element={<UserRouter />} />
              </Routes>
            ) : (
              <Routes>
                {(userProfile.roles.includes(UserRole.SINGLE_STATION) ||
                  userProfile.roles.includes(UserRole.DUO_STATION) ||
                  userProfile.roles.includes(UserRole.BATTLE_STATION)) && (
                  <Route path='*' element={<AdministrativeRouter />} />
                )}
                {userProfile.roles.includes(UserRole.STUDENT) && (
                  <Route path='*' element={<UserRouter />} />
                )}
              </Routes>
            )
          ) : (
            <LoginRoutes />
          )} */}
          {isAuthenticated ? (
            <div>
              {userProfile.role === UserRole.PLAYER || userProfile.role === UserRole.ADMIN ? (
                <Routes>
                  <Route path='*' element={<UserRouter />} />
                </Routes>
              ) : userProfile.role === UserRole.SINGLE_STATION ||
                userProfile.role === UserRole.DUO_STATION ? (
                <Routes>
                  <Route path='*' element={<AdministrativeRouter />} />
                </Routes>
              ) : null}
            </div>
          ) : (
            <LoginRoutes />
          )}
        </GoogleOAuthProvider>
      </Suspense>
      <ToastContainer
        position='bottom-right'
        closeOnClick
        limit={2}
        draggable={false}
        theme='dark'
        autoClose={3000}
      />
    </>
  );
};

export default App;
